import './App.css';

import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// import { Routes } from '../app/Routes';
import { I18nProvider } from './_metronic/i18n';
import { Layout } from './_metronic/layout';
import { LayoutSplashScreen, MaterialThemeProvider } from './_metronic/layout';
import { Switch, Route } from 'react-router-dom';
import { Map } from './components/Map';
import ChangePassword from './containers/ChangePassword';
import ChangeEmail from './containers/ChangeEmail';

function App({ store, persistor, basename }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={basename}>
            <MaterialThemeProvider>
              <I18nProvider>
                <Switch>
                  <Route exact path="/">
                    <Layout>
                      <main>
                        <Map store={store} />
                      </main>
                    </Layout>
                  </Route>
                  <Route path="/settings/password">
                    <Layout>
                      <ChangePassword />
                    </Layout>
                  </Route>
                  <Route path="/settings/email">
                    <Layout>
                      <ChangeEmail />
                    </Layout>
                  </Route>
                </Switch>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
