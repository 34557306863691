import { createSlice } from '@reduxjs/toolkit';

const initialMapState = {
  needReload: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialMapState,
  reducers: {
    updateNeedReload: (state, action) => {
      const needReload = action.payload;
      state.needReload = needReload;
    },
  },
});

export const { updateNeedReload } = mapSlice.actions;
