import {
  LayerProps,
  createTileLayerComponent,
  updateGridLayer,
  withPane,
} from '@react-leaflet/core';
import { TileLayer as LeafletTileLayer, TileLayerOptions, DomEvent, Util } from 'leaflet';
import { isValidCoords } from './Coords';

class TestGray extends LeafletTileLayer {
  createTile(coords: any, done: any): HTMLElement {
    const tile = document.createElement('img');

    DomEvent.on(tile, 'load', Util.bind(this._tileOnLoad, this, done, tile));
    DomEvent.on(tile, 'error', Util.bind(this._tileOnError, this, done, tile));

    if (this.options.crossOrigin || this.options.crossOrigin === '') {
      tile.crossOrigin = this.options.crossOrigin === true ? '' : this.options.crossOrigin;
    }

    /*
		 Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
		 http://www.w3.org/TR/WCAG20-TECHS/H67
		*/
    tile.alt = '';

    /*
		 Set role="presentation" to force screen readers to ignore this
		 https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
		*/
    tile.setAttribute('role', 'presentation');

    tile.src = this.getTileUrl(coords);

    if (isValidCoords(coords['x'], coords['y'], coords['z'])) {
      tile.className += ' valid';
    }

    return tile;
  }
}

// const TestGray = LeafletTileLayer.extend({});

export interface TileLayerProps extends TileLayerOptions, LayerProps {
  url: string;
}

export const TileLayer = createTileLayerComponent<LeafletTileLayer, TileLayerProps>(
  function createTileLayer({ url, ...options }, context) {
    return {
      instance: new TestGray(url, withPane(options, context)),
      context,
    };
  },
  updateGridLayer,
);
