/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export function QuickNotification() {
  return (
    <>
      {/* begin::Notifications Panel */}
      <div id="kt_quick_notifications" className="offcanvas offcanvas-left p-10">
        {/* begin::Header */}
        <div className="offcanvas-header d-flex align-items-center justify-content-between mb-10">
          <h3 className="font-weight-bold m-0">Notifications</h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_notifications_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
        {/* end::Header */}

        {/* begin::Content */}
        <div className="offcanvas-content pr-5 mr-n5">
          {/* begin::Nav */}
          <div className="navi navi-icon-circle navi-spacer-x-0">
            {/* begin::Item */}
            {/* <a href="#" className="navi-item">
              <div className="navi-link rounded">
                <div className="symbol symbol-50 symbol-circle mr-3">
                  <div className="symbol-label">
                    <i className="flaticon-bell text-success icon-lg"></i>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold font-size-lg">5 new user generated report</div>
                  <div className="text-muted">Reports based on sales</div>
                </div>
              </div>
            </a> */}
            {/* end::Item */}
          </div>
          {/* end::Nav */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Notifications Panel */}
    </>
  );
}
