/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_helpers';
import { useDispatch } from 'react-redux';
import { updatePosition } from '../../../../../modules/Position/_redux/positionSlice.js';

// https://codesandbox.io/s/qqzm67ykmq?file=/index.js:1961-1994
const { compose, withProps, lifecycle } = require('recompose');
const { withScriptjs } = require('react-google-maps');
const {
  StandaloneSearchBox,
} = require('react-google-maps/lib/components/places/StandaloneSearchBox');

const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDSIgN8UeoG6RVnsJ6iZxQqDIPXe3balew&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        places: [],
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();

          this.setState({
            places,
          });
        },
      });
    },
  }),
  withScriptjs,
)((props) => (
  <div data-standalone-searchbox="">
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
    >
      <div className="quick-search-form border-bottom pt-5 pb-1">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <span className="svg-icon svg-icon-lg">
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')} />
              </span>
            </span>
          </div>
          <input type="text" className="form-control " placeholder="Search..." />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="quick-search-close ki ki-close icon-sm text-muted"></i>
            </span>
          </div>
        </div>
      </div>
    </StandaloneSearchBox>
    <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">Places</div>
    <div className="mb-10">
      {props.places.map(({ place_id, formatted_address, geometry: { location } }) => (
        <div className="d-flex align-items-center flex-grow-1 mb-2" key={place_id}>
          <div className="symbol symbol-30 bg-transparent flex-shrink-0">
            <div className="svg-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Map/Direction1.svg')} />
            </div>
          </div>
          <div className="d-flex flex-column ml-3 mt-2 mb-2">
            <a
              href="#"
              className="font-weight-bold text-dark text-hover-primary"
              onClick={(e) => props.handleClick(location.lat(), location.lng())}
            >
              {formatted_address}
            </a>
            <span className="font-size-sm font-weight-bold text-muted">
              {' at '}({location.lat()}, {location.lng()})
            </span>
          </div>
        </div>
      ))}
      {/* begin::Item */}

      {/* end::Item */}
    </div>
  </div>
));

export function QuickSearch() {
  const dispatch = useDispatch();
  const handleClick = (lat, lon) => dispatch(updatePosition({ lat, lon }));
  return (
    <>
      {/* begin::Search Panel */}
      <div id="kt_quick_search" className="offcanvas offcanvas-left p-10">
        {/* begin::Header */}
        <div className="offcanvas-header d-flex align-items-center justify-content-between mb-5">
          <h3 className="font-weight-bold m-0">
            Search
            <small className="text-muted font-size-sm ml-2">places</small>
          </h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_search_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
        {/* end::Header */}

        {/* begin::Content */}
        <div className="offcanvas-content">
          {/* begin::Container */}
          <div
            className="quick-search quick-search-offcanvas quick-search-has-result"
            id="kt_quick_search_offcanvas"
          >
            {/* begin::Form */}
            <PlacesWithStandaloneSearchBox handleClick={handleClick} />
            {/* end::Form */}

            {/* begin::Wrapper */}
            <div className="quick-search-wrapper pt-5">
              <div className="quick-search-result">
                {/* begin::Message */}
                <div className="text-muted d-none">No record found</div>
                {/* end::Message */}
              </div>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Container */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Search Panel */}
    </>
  );
}
