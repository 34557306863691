/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import SVG from 'react-inlinesvg';
import { Dropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../_helpers';
import { DropdownItemToggler, DropdownMenu4 } from '../../../_partials/dropdowns';
import { useDispatch } from 'react-redux';
import { updatePosition } from '../../../../modules/Position/_redux/positionSlice.js';

export function AsideDashboard({ isActive }) {
  const dispatch = useDispatch();
  // const handleClick = (lat, lon) => dispatch(updatePosition({ lat, lon }));
  return (
    <div className={`tab-pane p-3 px-lg-7 py-lg-5 fade ${isActive && 'show active'}`}>
      <h3 className="p-2 p-lg-3 my-1 my-lg-3">Places</h3>

      {/* begin::List */}

      {/* begin::Item */}
      <div className="list-item hoverable p-2 p-lg-3 mb-2">
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}
          <div className="symbol symbol-40 symbol-light mr-4">
            <span className="symbol-label bg-hover-white">
              <span className="svg-icon h-50 align-self-center">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Map/Marker1.svg')} />
              </span>
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="text-muted text-hover-primary font-weight-bold"
              onClick={(e) => {
                const lat = 35.6897112;
                const lon = 139.6953356;
                dispatch(updatePosition({ lat, lon }));
              }}
            >
              <span className="text-dark-75 font-size-h6 mb-0">新宿駅西側</span>
              <br />
              x=29099/y=12902/z=15
            </a>
          </div>
          {/* begin::End */}
        </div>
      </div>
      {/* end::Item */}
      {/* end::List */}
      <h3 className="p-2 p-lg-3 my-1 my-lg-3">Document</h3>
      {/* begin::Item */}
      <div className="list-item hoverable p-2 p-lg-3 mb-2">
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}
          <div className="symbol symbol-40 symbol-light mr-4">
            <span className="symbol-label bg-hover-white">
              <span className="svg-icon h-50 align-self-center">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Book.svg')} />
              </span>
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="https://spacedata.gitbook.io/openearth/"
              className="text-muted text-hover-primary font-weight-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-dark-75 font-size-h6 mb-0">データインポート手順</span>
              <br />
              UE5
            </a>
          </div>
          {/* begin::End */}
        </div>
      </div>
      {/* end::Item */}
      {/* end::List */}
    </div>
  );
}
