/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_helpers';
import { Auth } from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { updateEmail } from '../../../../../modules/User/_redux/userSlice';
import { LinkContainer } from 'react-router-bootstrap';
import LoaderButton from '../../../../../components/LoaderButton';

export function QuickUser() {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [authState, setAuthState] = React.useState();
  const dispatch = useDispatch();
  const handleSignIn = (email) => dispatch(updateEmail(email));

  React.useEffect(() => {
    return onAuthUIStateChange((newAuthState) => {
      Auth.currentAuthenticatedUser()
        .then((data) => {
          handleSignIn(data.attributes.email);
        })
        .catch((err) => console.log(err));
      setAuthState(newAuthState);
    });
  }, []);

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">User Settings</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="d-flex flex-column">
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-notification.svg')}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">{user.email}</span>
                </span>
              </a>
            </div>
            <button
              className="btn btn-light-primary btn-bold"
              type="button"
              onClick={() => Auth.signOut()}
            >
              Sign out
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        {/* https://serverless-stack.com/chapters/allow-users-to-change-passwords.html */}
        <div className="Settings">
          {/* TODO: メールとパスワードは変更できないようにする */}
          {/* <LinkContainer to="/settings/email">
            <LoaderButton block bsSize="large">
              Change Email
            </LoaderButton>
          </LinkContainer>
          <LinkContainer to="/settings/password">
            <LoaderButton block bsSize="large">
              Change Password
            </LoaderButton>
          </LinkContainer> */}
        </div>
        <div className="separator separator-dashed my-7"></div>
      </div>
    </div>
  );
}
