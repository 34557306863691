import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  email: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateEmail: (state, action) => {
      const email = action.payload;
      state.email = email;
    },
  },
});

export const { updateEmail } = userSlice.actions;
