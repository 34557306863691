/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useHtmlClassService } from '../_metronic/layout';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useSelector } from 'react-redux';
import { MapContainer, Marker, Popup, useMapEvents, Tooltip, useMap, Polygon } from 'react-leaflet';
import { TileLayer } from './TileLayer.ts';
import { isValidCoords, validPolygons } from './Coords';
import { updateNeedReload } from '../modules/Map/_redux/mapSlice';
import { useLang } from '../_metronic/i18n';

Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/';

if (typeof Number.prototype.toRad === 'undefined') {
  Number.prototype.toRad = function () {
    return (this * Math.PI) / 180;
  };
}

function convertTileCoordsFromLatLng(lat, lng, zoom) {
  const x = parseInt(Math.floor(((lng + 180) / 360) * (1 << zoom)));
  const y = parseInt(
    Math.floor(
      ((1 - Math.log(Math.tan(lat.toRad()) + 1 / Math.cos(lat.toRad())) / Math.PI) / 2) *
        (1 << zoom),
    ),
  );
  return { x: x, y: y, z: zoom };
}

function convertLatLngFromTileCoords(x, y, z) {
  const lng = (x / Math.pow(2.0, z)) * 360 - 180;
  const mapy = (y / Math.pow(2.0, z)) * 2 * Math.PI - Math.PI;
  const lat = (2 * Math.atan(Math.exp(-mapy)) * 180) / Math.PI - 90;
  return { lat: lat, lng: lng };
}

function getTileURL(lat, lon, zoom) {
  var xtile = parseInt(Math.floor(((lon + 180) / 360) * (1 << zoom)));
  var ytile = parseInt(
    Math.floor(
      ((1 - Math.log(Math.tan(lat.toRad()) + 1 / Math.cos(lat.toRad())) / Math.PI) / 2) *
        (1 << zoom),
    ),
  );
  return 'x=' + xtile + ', y=' + ytile + ', z=' + zoom;
}

function LocationMarker() {
  const [tilePosition, setPosition] = useState(null);
  const [url, setUrl] = useState(null);
  const [displayedCoords, setDisplayedCoords] = useState(null);
  const [displayed, setDisplayed] = useState(null);
  const map = useMapEvents({
    click(e) {
      let { lat, lng } = e.latlng;
      let zoom = map.getZoom();
      const { x, y, z } = convertTileCoordsFromLatLng(lat, lng, zoom);
      const leftTop = convertLatLngFromTileCoords(x, y, z);
      const rightBottom = convertLatLngFromTileCoords(x + 1, y + 1, z);
      setPosition({
        lat: (leftTop.lat + rightBottom.lat) / 2.0,
        lng: (leftTop.lng + rightBottom.lng) / 2.0,
      });
      setDisplayed(isValidCoords(x, y, z));
      setUrl(`https://space-data-osm-files.s3.us-west-2.amazonaws.com/x${x}y${y}z${z}.zip`);
      setDisplayedCoords(getTileURL(lat, lng, zoom));
    },
  });

  const clickDownload = () => {
    const element = document.createElement('a');
    element.href = 'https://pub-c6d19d22432745c3b5e3115e85028453.r2.dev/shinjuku.zip';
    element.download = 'download.zip';
    document.body.appendChild(element);
    element.click();
  };

  return tilePosition === null ? null : (
    <Popup position={tilePosition}>
      {displayed ? (
        <>
          <table>
            <tbody>
              <tr>
                <td align="right">Cord:</td>
                <td>{displayedCoords}</td>
              </tr>
              <tr>
                <td align="right">Created:</td>
                <td>2024-04-05 21:00:00</td>
              </tr>
              <tr>
                <td align="right">Version:</td>
                <td>202404</td>
              </tr>
            </tbody>
          </table>
          <div>
            <button type="button" onClick={clickDownload} className="btn btn-success btn-sm">
              ダウンロード(UE5.1 Project)
            </button>
          </div>
          {/* <button type="button" onClick={clickDownload} className="btn btn-success btn-sm mt-1">
            Download(FBX)
          </button> */}
        </>
      ) : (
        <p>まだダウンロードできないタイルです。</p>
      )}
    </Popup>
  );
}

// 描画後にブラウザの広さを確認して広げる
function invalidateSize(map) {
  window.setTimeout(function () {
    map.invalidateSize();
  }, 1000);
}

const zoom = 15;

function DisplayPosition({ map, store }) {
  invalidateSize(map);

  store.subscribe(() => {
    invalidateSize(map);
    const position = store.getState().position;
    map.setView([position.lat, position.lon], zoom);
  });

  return <></>;
}

function LeafletMap({ store, position }) {
  const [map, setMap] = useState(null);
  const lang = useLang();
  // 中国語: hl=zh-CN&gl=cn
  const url = 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=' + lang + '&apistyle=s.t:0';
  const displayMap = useMemo(
    () => (
      <MapContainer
        style={{}}
        center={[position.lat, position.lon]}
        zoom={zoom}
        scrollWheelZoom={false}
        whenCreated={setMap}
      >
        <TileLayer
          // OSM
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // Google Map
          attribution="&copy; Google"
          url={url}
        />
        <Polygon positions={validPolygons()} />
        <LocationMarker />
      </MapContainer>
    ),
    [],
  );

  return (
    <div>
      {map ? <DisplayPosition map={map} store={store} /> : null}
      {displayMap}
    </div>
  );
}

export function Map({ store }) {
  const position = useSelector((state) => state.position);
  const uiService = useHtmlClassService();

  return (
    <>
      <LeafletMap store={store} position={position} />
    </>
  );
}
