import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as _redux from './redux';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from './_metronic/layout';
import { MetronicI18nProvider } from './_metronic/i18n';
import { Amplify, I18n } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import store, { persistor } from './redux/store';
import qs from 'qs';
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
// Datepicker
import 'react-datepicker/dist/react-datepicker.css';

import './index.scss';

const dict = {
  ja: {
    'Forgot your password?': 'パスワードを忘れた場合',
    'Reset password': 'パスワードをリセット',
    'No account?': 'アカウントを持っていない場合',
    'Create account': 'アカウント作成',
    'Sign in to your account': 'ログイン',
    'Sign In': 'ログイン画面',
    'Enter your email address': 'メールアドレスを入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Have an account?': 'アカウントを持っている場合',
    'Sign in': 'ログイン',
    'Back to Sign In': 'ログイン画面に戻る',
    'Confirm Sign up': 'メールアドレス確認',
    'Lost your code?': 'コードを紛失した場合',
    'Resend Code': 'コードを再送信',
    'Email Address': 'メールアドレス',
    'Confirmation Code': '確認コード',
    'Enter your code': 'コードを入力してください',
    Confirm: '確認',
    'Email Address *': 'メールアドレス *',
    Password: 'パスワード',
    'Reset your password': 'パスワードをリセット',
    'Send Code': 'コードを送信',
    'Verification code': '確認コード',
    'Enter code': 'コードを入力してください',
    'New Password': '新しいパスワード',
    'New password': '新しいパスワード',
    'Enter your new password': '新しいパスワードを入力してください',
    'Exceeded daily email limit for the operation or the account. If a higher limit is required, please configure your user pool to use your own Amazon SES configuration for sending email.':
      '現在利用人数を制限しています。順次利用可能人数を増やしているため、時間をおいて登録した情報にてログインをお願いします。',
    Submit: '登録',
  },
};
I18n.putVocabularies(dict);
I18n.setLanguage('ja');

Amplify.configure({ ...awsExports });

const { PUBLIC_URL } = process.env;

_redux.setupAxios(axios, store);

Sentry.init({
  dsn: 'https://e351e4d913364120b6f2a205c37a2bef@o559119.ingest.sentry.io/5693506',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <MetronicI18nProvider>
      <AmplifyAuthenticator
        usernameAlias="email"
        initialAuthState={
          qs.parse(window.location.search, { ignoreQueryPrefix: true }).auth === 'signup'
            ? 'signup'
            : 'signin'
        }
      >
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          submitButtonText="アカウント作成"
          headerText="アカウント作成画面"
          formFields={[
            {
              type: 'email',
              label: 'メールアドレス',
              placeholder: 'メールアドレスを入力',
              required: true,
            },
            {
              type: 'password',
              label: 'パスワード',
              placeholder: 'パスワードを入力',
              required: true,
            },
          ]}
        />
        <AmplifySignIn
          slot="sign-in"
          usernameAlias="email"
          submitButtonText="ログイン"
          headerText="ログイン画面"
          formFields={[
            {
              type: 'email',
              label: 'ログインID(メールアドレス) *',
              placeholder: 'メールアドレスを入力',
              required: true,
            },
            {
              type: 'password',
              label: 'パスワード *',
              placeholder: 'パスワードを入力',
              required: true,
            },
          ]}
        />
        <MetronicLayoutProvider>
          <MetronicSubheaderProvider>
            <MetronicSplashScreenProvider>
              <App store={store} persistor={persistor} basename={PUBLIC_URL} />
            </MetronicSplashScreenProvider>
          </MetronicSubheaderProvider>
        </MetronicLayoutProvider>
      </AmplifyAuthenticator>
    </MetronicI18nProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
