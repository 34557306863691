const valid_tiles = [{ x: 29099, y: 12902, z: 15 }];

export const isValidCoords = (x: number, y: number, z: number): boolean => {
  return z === 15 && x === 29099 && y === 12902;
};

export const validPolygons = () => {
  return valid_tiles.map(({ x, y, z }) => {
    return [
      calTileLonLat(x, y, z),
      calTileLonLat(x + 1, y, z),
      calTileLonLat(x + 1, y + 1, z),
      calTileLonLat(x, y + 1, z),
    ];
  });
};

const calTileLonLat = (x: number, y: number, z: number): [number, number] => {
  const n = 2 ** z;
  const lon = (x / n) * 360 - 180;
  const lat = (Math.atan(Math.sinh(Math.PI * (1 - (2 * y) / n))) * 180) / Math.PI;
  return [lat, lon];
};
